.quantity-input-container {
    input {
        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            display: none;
        }
    }

    button {
        position: absolute;
        right: -20px;
        bottom: -5px;

        svg {
            fill: #fff;
        }
    }
}