body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "SoDoSans";
  src: local("SoDoSans"),
   url("./assets/fonts/SoDo-Sans/SoDoSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
 }

 @font-face {
  font-family: "SoDoSans";
  src: local("SoDoSans"),
   url("./assets/fonts/SoDo-Sans/SoDoSans-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
 }

 @font-face {
  font-family: "SoDoSans";
  src: local("SoDoSans"),
   url("./assets/fonts/SoDo-Sans/SoDoSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
 }

 @media print {
  /* All your print styles go here */
  .MuiBox-root{
    margin-top: 0 !important;
  }

  .MuiDrawer-root,
  .MuiLink-button,
  button,
  .navigation-holder,
  .MuiPaper-root {
    display: none !important;
  }

  .MuiOutlinedInput-input{
    max-width:300px !important; 
  }

  .page-area{
    padding: 0 !important;
  }

  .stockcount-table-container{
   border: none !important; 
   box-shadow: none !important;
  }

  .stockcount-table td,
  .stockcount-table th{
    padding: 2px 0 !important; 
    height: auto !important;
  }

  .stockcount-table input{
    height: 20px !important; 
    padding: 2px !important; 
    color: white !important;
  }

  .quantity-fields-wrapper{
    flex-wrap: nowrap !important;
  }

  .stockcount-table .quantity-field{
    height: 24px !important; 
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    max-width: 50px !important;

  }

  .MuiGrid-root{
    margin-left: 0 !important;
  }
}